import React, { useState } from "react"
import { navigate } from "gatsby"
import axios from "axios"
import {
  toggleCityName,
  toggleMoveInDate,
  toggleMoveOutDate,
  toggleLocale,
  togglePropertyFeature,
  toggleBedType,
  toggleHousingType,
  togglePageNumber,
  toggleCityList,
  toggleMaxPrice,
  toggleMinPrice,
  toggleReplacePropertyFeature,
  toggleReplaceBedType,
  toggleReplaceHousingType,
  toggleReplaceRentType,
  toggleRentType,
  toggleTutionFeeRange,
  toggleDetailPage,
  reset,
} from "../state/action"
import "../assets/css/housing-list.css"
import ReactPaginate from "react-paginate"
import BounceIndicator from "react-activity/lib/Bounce"
import "../assets/css/paginatestyle.css"
import { useSelector, useDispatch } from "react-redux"
import BasicAutoSuggest from "../components/basic.autosuggest"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import $, { data } from "jquery"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import SEO from "../components/seo"
import getSymbolFromCurrency from 'currency-symbol-map'
import RangeScrolling from "../components/scrolling"

//


import { Radio, Input, Space } from 'antd';
import 'antd/lib/radio/style/index.css'
import 'antd/lib/input/style/index.css'
import 'antd/lib/space/style/index.css'



const Accommodation = housing_content => {
  var locationID = ""
  if (typeof window !== `undefined`) {
    locationID = document.location.search.substring(1)
  }
  // console.log("#####################url",locationID)
  const URLParams = new URLSearchParams(locationID)
  const dispatch = useDispatch()
  const cityName = useSelector(state => state.app.cityName)
  const tutionFeeRange = useSelector(state => state.app.tutionFeeRange)
  const moveInDate = useSelector(state => state.app.moveInDate)
  const moveOutDate = useSelector(state => state.app.moveOutDate)
  const locale = useSelector(state => state.app.locale)
  const bedType = useSelector(state => state.app.bedType)
  const pageNumber = useSelector(state => state.app.pageNumber)
  const rentType = useSelector(state => state.app.rentType)
  const cityList = useSelector(state => state.app.cityList)
  const detail = useSelector(state => state.app.detail)
  const propertyFeatures = useSelector(state => state.app.propertyFeatures)
  const housingType = useSelector(state => state.app.housingType)
  const maxPrice = useSelector(state => state.app.maxPrice)
  const minPrice = useSelector(state => state.app.minPrice)
  const [bedValue, setBedValue] = useState("")
  const [housingTypeValue, setHousingTypeValue] = useState("")
  const [resetCity, setResetCity] = useState(false)
  const [tutionFee, setTutionFee] = useState("")
  const [value, setValue] = useState("")
  const [rentValue, setRentValue] = useState("")
  const [renderData, setRenderData] = useState()
  const [fee, setFee] = useState({ checkedItems: new Map() })
  const [localeCheck, setLocaleCheck] = useState({ checkedItems: new Map() })
  const [rentCheck, setRentCheck] = useState({ checkedItems: new Map() })
  const [housingCheck, setHousingCheck] = useState({ checkedItems: new Map() })
  const [propertyCheck, setPropertyCheck] = useState({
    checkedItems: new Map(),
  })
  const [bedCheck, setBedCheck] = useState({ checkedItems: new Map() })
  const OwlCarousel = Loadable(() => import("react-owl-carousel"))
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [pageCount, setPageCount] = useState(1)
  const [total_found, setTotal_found] = useState("")
  const [paginateRenderData, setPaginateRenderData] = useState()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [currencySymbol, setCurrencySymbol] = useState("€")
  const [toggle, setToggle] = useState({
    rent: true,
    location: true,
    locale: true,
    duration: true,
    bed: true,
    rentType: true,
    house: true,
    property: true,
  })

  ///
  const [rvalue, setrValue] = React.useState([0, 2400]);

  const [months,setMonths] = React.useState(0)

  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});

  React.useEffect(() => {
    dispatch(toggleDetailPage(false))
  }, [])

  React.useEffect(() => {
    // let isMounted = true;
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        $(document).ready(function () {
          $(".filters-menu").removeClass("show")
          $(".filter-reset").removeClass("show")
          $(".filter-title").click(function () {
            if ($(".filters-menu").hasClass("show")) {
              $(".filters-menu").removeClass("show")
              $(".filter-reset").removeClass("show")
            } else {
              $(".filters-menu").addClass("show")
              $(".filter-reset").addClass("show")
            }
          })
        })
      }
    }
  }, [])
  // useEffect(() => {
  //   if (window.location.href.split("?").length > 1) {
  //     console.log(window.location.href.split("?")[1], "cityName")
  //     let url = new URL(window.location.href)
  //     let params = new URLSearchParams(url.search.slice(1))
  //     console.log(params, "params")

  //     // var searchParams = new URLSearchParams((window.location.href).split("?")[1]);
  //     for (var pair of params.entries()) {
  //       // params.getAll(pair[i])
  //       console.log(pair[0], "url value")
  //       if (pair[0] === "bed-type") {
  //         HandleBedType(pair[1], true)
  //       }
  //       if (pair[0] === "property-features") {
  //         HandlePropertyFeatures(pair[1], true)
  //       }

  //       if (pair[0] === "rent-type") {
  //         HandleRentType(pair[1], true)
  //       }
  //       if (pair[0] === "housing-type") {
  //         console.log(pair[0],pair[1], "existed values")
  //         HandleHousingType(pair[1], true)
  //       }
  //       if (pair[0] === "budget-min") {
  //         dispatch(toggleMinPrice(pair[1]))
  //       }
  //       if (pair[0] === "budget-max") {
  //         dispatch(toggleMaxPrice(pair[1]))
  //         console.log(pair[1],"pair[1]")
  //       }
  //       if (pair[0] === "move-in") {
  //         dispatch(toggleMoveInDate(pair[1]))
  //         console.log(pair[1],"time")
  //         console.log(new Date(pair[1]),"time1")
  //        setStartDate(new Date(pair[1]))
  //       }
  //       if (pair[0] === "move-out") {
  //         dispatch(toggleMoveOutDate(pair[1]))
  //         setEndDate(new Date(pair[1]))

  //       }
  //     }
  //   }
  // }, [])

  // useEffect(()=>{
  //   if(URLParams.get("city")){
  //     dispatch(toggleCityName(URLParams.get("city")))
  //   }

  // },[cityName])

  React.useEffect(() => {
    console.log("cityName")
    if (!detail) {
      AxiosResponseData()
    }
  }, [
    cityName,
    pageNumber,
    tutionFeeRange,
    moveInDate,
    moveOutDate,
    value,
    bedValue,
    rentValue,
    //  propertyFeatures,
    housingTypeValue,
    URLParams.get("id"),
    detail,
  ])
  React.useEffect(() => {
    function data() {
      if (bedType.length) {
        bedType.map(e => bedCheck.checkedItems.set(e, true))
      }
      if (rentType.length) {
        rentType.map(e => rentCheck.checkedItems.set(e, true))
      }
      if (propertyFeatures.length) {
        propertyFeatures.map(e => propertyCheck.checkedItems.set(e, true))
      }
      if (housingType.length) {
        housingType.map(e => housingCheck.checkedItems.set(e, true))
      }
      if (tutionFeeRange) {
        // var data=minPrice/100+"-"+maxPrice/100
        // console.log(data,"data")
        fee.checkedItems.set(minPrice / 100 + "-" + maxPrice / 100, true)
      }
      if (moveInDate) {
        setStartDate(new Date(moveInDate))
      }
      if (moveOutDate) {
        setEndDate(new Date(moveOutDate))
      }
      // if(cityName){

      // }
    }
    data()
  }, [
    bedType,
    housingType,
    propertyFeatures,
    cityName,
    rentType,
    detail,
    tutionFeeRange,
    moveInDate,
    moveOutDate,
  ])
  // React.useEffect(()=>{
  //   if(maxPrice &&minPrice){
  //     console.log(maxPrice,minPrice,"maxPrice")
  // var data=minPrice/100+"-"+maxPrice/100
  // console.log(data,"data")
  // fee.checkedItems.set(data, true)
  //   }
  //   if(maxPrice&&(minPrice===0)){
  //     var data=minPrice+"-"+maxPrice/100
  //     console.log(data,"data")
  //     fee.checkedItems.set(data, true)

  //   }
  //   if(minPrice&&(maxPrice=="")){
  //     var data=minPrice/100
  //     console.log(data,"datamin")
  //     fee.checkedItems.set("20000", true)
  //     // fee.checkedItems.set("20000", true)

  //   }

  // },[maxPrice,minPrice])
  React.useEffect(() => {
    async function fetchBedData() {
      await bedTypeFunc()
    }
    fetchBedData()
  }, [bedType, detail])
  // React.useEffect(() => {
  //   HandlePageClick();
  // },[pageNumber])

  React.useEffect(() => {
    async function fetchRentData() {
      await rentTypeFunc()
    }
    fetchRentData()
  }, [rentType, detail])

  React.useEffect(() => {
    async function fetchData() {
      await featureFunc()
    }
    fetchData()
  }, [propertyFeatures, detail])
  React.useEffect(() => {
    if (total_found) {
      HandleTotal_Found()
    }
  }, [total_found])

  React.useEffect(() => {
    async function fetchHousingData() {
      await housingTypeFunc()
    }
    fetchHousingData()
  }, [housingType, detail])

  React.useEffect(() => {
    async function fetchData() {
      if (startDate) {
        console.log(startDate, "startDate")

        if (startDate !== undefined) {
          // let url = new URL(window.location.href)
          // var searchParams = new URLSearchParams(url.search.slice(1))
          // console.log(searchParams.getAll("move-in"),"property-features",moveInDate)
          // if((searchParams.getAll("move-in")).filter(c=>c ===moveInDate).length ===0){
          //   searchParams.set("move-in", moveInAndMoveOut(startDate))
          //   window.location.search = searchParams.toString();
          // }
          // urlAppendComponent('move-in',moveInDate)
          dispatch(toggleMoveInDate(moveInAndMoveOut(startDate)))
        }
      } else {
        dispatch(toggleMoveInDate(""))
      }
      if (endDate) {
        // let url = new URL(window.location.href)
        // var searchParams = new URLSearchParams(url.search.slice(1))
        // console.log(searchParams.getAll("move-out"),"property-features",endDate)
        // if((searchParams.getAll("move-out")).filter(c=>c ===moveOutDate).length ===0){
        //   searchParams.set("move-out", moveInAndMoveOut(endDate))
        //   window.location.search = searchParams.toString();
        // }
        dispatch(toggleMoveOutDate(moveInAndMoveOut(endDate)))
      } else {
        dispatch(toggleMoveOutDate(""))
      }
      // if(startDate !==undefined && endDate !==undefined){

      // }
    }
    fetchData()
  }, [startDate, endDate, detail])

  function HandleTotal_Found() {
    return `Showing ${total_found ? total_found : ""} Housing properties`
  }

  function moveInAndMoveOut(e) {
    var dateArray = e.toString().split(" ")

    var months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ]
    var month = months.indexOf(dateArray[1].toLowerCase()) + 1

    var zero = month > 9 ? "" : 0

    var date2 = dateArray[3] + "-" + zero + month + "-" + dateArray[2]
    return date2
  }

  async function featureFunc() {
    if (propertyFeatures.length !== 0) {
      console.log(
        propertyFeatures.length,
        "dddddddddddddddddddddddddddddddddddd"
      )
      await propertyFeatures.map((features, index) => {
        if (index === 0) {
          setValue("&property-features=" + features)
        } else {
          setValue(value + "," + features)
        }
      })
    } else {
      console.log(propertyFeatures.length, "dddd")

      setValue("")
    }
  }

  async function rentTypeFunc() {
    if (rentType.length !== 0) {
      await rentType.map((rent, index) => {
        if (index === 0) {
          setRentValue("&rent-type=" + rent)
          // console.log(features, "first element", index)
        } else {
          setRentValue(rentValue + "," + rent)
          // console.log(features, "non first element", index)
        }
      })
    } else {
      setRentValue("")
    }
  }

  async function housingTypeFunc() {
    if (housingType.length !== 0) {
      await housingType.map((features, index) => {
        if (index === 0) {
          setHousingTypeValue("&housing-type=" + features)
          // console.log(features, "first element", index)
        } else {
          setHousingTypeValue(housingTypeValue + "," + features)
          // console.log(features, "non first element", index)
        }
      })
    } else {
      setHousingTypeValue("")
    }
  }

  async function bedTypeFunc() {
    if (bedType.length !== 0) {
      await bedType.map((bed, ind) => {
        if (ind === 0) {
          setBedValue("&bed-type=" + bed)
          // console.log(bed, "first element", ind)
        } else {
          setBedValue(bedValue + "," + bed)
          // console.log(bed, "non first element", ind)
        }
      })
    } else {
      setBedValue("")
    }
  }

  const AxiosResponseData = async () => {
    if (cityList.length === 0) {
      setLoading1(true)
      setLoading(true)
      setLoading2(true)
      console.log("entered in to  ===>cityList")
      ///backend express
      const headers = {
        "Content-Type": "application/json",
      }
      await axios
        .post(
          process.env.HOUSING_LIST_API_URL,
          {
            url: `${process.env.HOUSING_BASE_URL}/v1/cities`,
            token: process.env.UNIVPLACES_BASE_API_KEY,
          },
          { headers }
        )
        .then(function (response) {
          console.log("posted express response", response)
          response.data && dispatch(toggleCityList(response.data))
          response.data && setLoading1(false)
          // var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + "?city=FR-paris";
          // window.history.pushState({ path: refresh }, '', refresh);
        })
        .catch(err => {
          console.log(err, "////////")
        })
    }

    if (cityName) {
      setLoading(true)
      // setLoading2(true)
      $("html, body").animate({ scrollTop: 0 }, 250)
      console.log("entered in to  ===>cityName")

      ///backend express

      const headers = {
        "Content-Type": "application/json",
      }

      const data = await axios
        .post(
          process.env.HOUSING_LIST_API_URL,
          {
            url: `${process.env.HOUSING_BASE_URL}/v1/cities/${cityName}/offers?limit=10&page=${pageNumber}&budget-max=${maxPrice}&budget-min=${minPrice}&move-in=${moveInDate}&move-out=${moveOutDate}${bedValue}${value}${housingTypeValue}${rentValue}`,
            token: process.env.UNIVPLACES_BASE_API_KEY,
          },
          { headers }
        )
        .then(res => {
          console.log("posted12", res.data)

          res.data && setRenderData(res.data.data)
          res.data && setCurrencySymbol(getSymbolFromCurrency(res.data.data.length > 0 ? res.data.data[0].attributes.accommodation_offer.price.currency_code : "EUR"))
          res.data &&
            setPageCount(res.data.meta ? res.data.meta.total_page_number : "")
          res.data &&
            setTotal_found(res.data.meta ? res.data.meta.total_found : "")
          console.log(
            `${process.env.HOUSING_BASE_URL}/v1/cities/${cityName}/offers?limit=10&page=${pageNumber}&budget-max=${maxPrice}&budget-min=${minPrice}&move-in=${moveInDate}&move-out=${moveOutDate}${bedValue}${value}${housingTypeValue}${rentValue}`,
            "data12"
          )
          res.data && console.log(res.data.meta.total_found, "found")
          // res.data &&
          //   console.log(res.data.meta ? res.data.meta.total_page_number : "")
          res.data && setRenderData(res.data.data)
          res.data && setLoading(false)
          // res.data&&setResetCity(false)
          res.data && setLoading2(false)
        })
        .catch(err => {
          console.log(err, "////////")
        })
      ///backend express

      return data
    }
  }

  // const HandleCityName = async () => {
  //   // let url = new URL(window.location.href);
  //   // let params = new URLSearchParams(url.search);
  //   // params.set('city','FR-paris');
  //   console.log("#########################################")
  //   await dispatch(toggleCityName("FR-paris"))
  //   setCityName(cityName)
  // }
  // function urlAppendComponent(parameter,id){
  //   console.log("url append called")
  //   let url = new URL(window.location.href)
  //   var searchParams = new URLSearchParams(url.search.slice(1))
  //   console.log(searchParams.getAll(parameter),"property-features")
  //   if((searchParams.getAll(parameter)).filter(c=>c ===id).length ===0){
  //     searchParams.append(parameter, id)
  //     window.location.search = searchParams.toString();
  //   }
  // }
  // function urlDeleteComponent(parameter,id,valuesArray){
  //   console.log("url deleting component");
  //   let url = new URL(window.location.href)
  //   var searchParams = new URLSearchParams(url.search.slice(1))
  // // console.log((searchParams.getAll('property-features')).filter(c=>c !==id),"property1")
  // if((searchParams.getAll(parameter)).filter(c=>c !==id).length !==0){
  //   searchParams.delete(parameter)
  //   window.location.search = searchParams.toString();
  //   valuesArray.filter(c=>c !==id).map(value=>{
  //     searchParams.append(parameter,value)
  //     window.location.search = searchParams.toString();
  //   })
  // }
  // if((searchParams.getAll(parameter)).filter(c=>c !==id).length ===0){
  //   console.log("property-features deleted")
  //   searchParams.delete(parameter)
  //   window.location.search = searchParams.toString();
  // }

  // }

  //   function insertUrlParam(key, value) {
  //     if (history.pushState) {
  //         let searchParams = new URLSearchParams(window.location.search);
  //         searchParams.set(key, value);
  //         let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
  //         window.history.pushState({path: newurl}, '', newurl);
  //     }
  // }

  const HandleTutionFee = async e => {
    fee.checkedItems.set(e.target.id, e.target.checked)

    for (let [key, value] of fee.checkedItems.entries()) {
      if (e.target.id !== key) {
        setFee(prevState => ({
          checkedItems: prevState.checkedItems.set(key, false),
        }))
      }
      if (key === "0-0") {
        fee.checkedItems.delete(key)
      }
    }
    console.log(fee, "check")

    if (e.target.checked) {
      var fees = e.target.id.split("-")
      // console.log(fees, "fees check list---------->>>>")

      if (fees.length === 1) {
        if (fees[0] == 0) {
          console.log(pageNumber, "fees")
          dispatch(togglePageNumber(1))
          dispatch(toggleMinPrice(fees[0] * 100))
          dispatch(toggleMaxPrice(""))
          dispatch(toggleTutionFeeRange(fees[0]))
        } else {
          dispatch(togglePageNumber(1))
          console.log(pageNumber, "fees")
          dispatch(toggleMinPrice(fees[0] * 100))
          dispatch(toggleMaxPrice(""))
          dispatch(toggleTutionFeeRange(fees[0]))
        }
      }
      if (fees.length === 2) {
        dispatch(togglePageNumber(1))

        dispatch(toggleMinPrice(fees[0] * 100))
        console.log('min prices', fees[0] * 100)
        console.log('max prices', fees[1] * 100)
        console.log('range', fees[0] + fees[1])

        dispatch(toggleMaxPrice(fees[1] * 100))
        dispatch(toggleTutionFeeRange(fees[0] + fees[1]))
      }
    } else {
      dispatch(togglePageNumber(1))

      dispatch(toggleMinPrice(""))
      dispatch(toggleMaxPrice(""))
      dispatch(toggleTutionFeeRange(""))
    }

    // await dispatch(toggleTutionFeeRange(e.target.id))
    // .map((fee,index) => {
    //   index===1? dispatch(toggleMaxPrice(fee)):null;
    //  })

    setTutionFee(tutionFeeRange)
  }

  const HandleMoveInDate = async () => {
    await dispatch(toggleMoveInDate("2021-08-10"))
  }

  const HandleMoveOutDate = async e => {
    await dispatch(toggleMoveOutDate(e.target.id))
  }

  const HandleLocale = async e => {
    localeCheck.checkedItems.set(e.target.id, e.target.checked)
    if (e.target.checked) {
      await dispatch(toggleLocale(e.target.id))
    } else {
      await dispatch(toggleLocale(""))
    }
  }

  const HandlePropertyFeatures = async (id, check) => {
    propertyCheck.checkedItems.set(id, check)
    dispatch(togglePageNumber(1))
    if (check === false) {
      // urlDeleteComponent('property-features',id,propertyFeatures)

      if (propertyFeatures.filter(c => c !== id).length !== 0) {
        console.log("Property features")

        await dispatch(
          toggleReplacePropertyFeature(propertyFeatures.filter(c => c !== id))
        )
      } else {
        await dispatch(toggleReplacePropertyFeature([]))
      }
    } else {
      console.log(id, check, "id and check")
      // urlAppendComponent("property-features",id);

      await dispatch(togglePropertyFeature(id))
    }
  }

  const HandleBedType = async (id, check) => {
    bedCheck.checkedItems.set(id, check)
    dispatch(togglePageNumber(1))

    console.log(bedCheck, "bedcheck")
    // if (bedType.length === 0) {
    //   // urlAppendComponent("bed-type",id);
    //   bedCheck.checkedItems.set(id, check)
    //   await dispatch(toggleBedType(id))
    // } else {
    if (check === false) {
      // urlDeleteComponent("bed-type",id,bedType)
      if (bedType.filter(c => c !== id).length !== 0) {
        // urlDeleteComponent("bed-type",id,bedType)
        await dispatch(toggleReplaceBedType(bedType.filter(c => c !== id)))
      } else {
        await dispatch(toggleReplaceBedType(bedType.filter(c => c !== id)))
      }
    } else {
      // urlAppendComponent("bed-type",id);
      bedCheck.checkedItems.set(id, check)
      await dispatch(toggleBedType(id))
    }
    // }
  }

  const HandleHousingType = async (id, check) => {
    housingCheck.checkedItems.set(id, check)
    dispatch(togglePageNumber(1))
    // if (housingType.length === 0) {
    //   // urlAppendComponent("housing-type",id);
    //   await dispatch(toggleHousingType(id))
    // } else {
    if (check === false) {
      // urlDeleteComponent("housing-type",id,housingType)
      // if (housingType.filter(c => c !== id).length !== 0) {
      //   // urlDeleteComponent("housing-type",id,housingType)
      //   await dispatch(toggleReplaceHousingType(housingType.filter(c => c !== id)))
      // }
      // else{
      await dispatch(
        toggleReplaceHousingType(housingType.filter(c => c !== id))
      )
      // }
    } else {
      // urlAppendComponent("housing-type",id);
      await dispatch(toggleHousingType(id))
    }
    // }
  }

  const HandleRentType = async (id, check) => {
    rentCheck.checkedItems.set(id, check)
    dispatch(togglePageNumber(1))

    if (check === false) {
      // urlDeleteComponent("rent-type",id,rentType)
      await dispatch(toggleReplaceRentType(rentType.filter(c => c !== id)))
    } else {
      // insertParam("rent-type",id)
      // urlAppendComponent("rent-type",id);
      // insertUrlParam("rent-type",id)
      await dispatch(toggleRentType(id))
    }

    // await dispatch(togglePageNumber(e.target.id))
  }

  const HandlePagination = async e => {
    await dispatch(togglePageNumber(e.target.id))
  }

  const HandleAvailability = timestamp => {
    if (timestamp) {
      // console.log(timestamp,"timestamp")
      var date = new Date(timestamp)
      var zero = date.getMonth() + 1 > 9 ? "" : 0

      var date2 =
        date.getFullYear() +
        "-" +
        zero +
        (date.getMonth() + 1) +
        "-" +
        date.getDate()

      // console.log(date2,"date")

      if (date2) {
        const subdate = date2.split("-")
        // let date1 = new Date(
        //   parseInt(subdate[0]),
        //   parseInt(subdate[1]) - 1,
        //   parseInt(subdate[2])
        // ) // 2020-06-21
        let date1 = new Date(
          parseInt(subdate[0]),
          parseInt(subdate[1]) - 1,
          parseInt(subdate[2])
        ) // 2020-06-21

        let longMonth = date1.toLocaleString("en-us", { month: "long" })
        let value1 =
          parseInt(subdate[2]) + " " + longMonth + " " + parseInt(subdate[0])

        return value1
      } else {
        return
      }
    } else {
      return
    }
  }

  // const pageCount=renderData?Math.ceil(renderData.length / 10):0

  const HandlePageClick = async e => {

    const selectedPage = e.selected
    console.log(e, e.selected, "selectedpage")
    // console.log(selectedPage+1, "this is city name")
    await dispatch(togglePageNumber(selectedPage + 1))



  }
  function call() {
    return dispatch(reset())
  }

  const HandleResetAll = async e => {
    // e.preventDefault()
    //  setLoading(true)

    setStartDate()
    setEndDate()
    setFee({ checkedItems: new Map() })
    setLocaleCheck({ checkedItems: new Map() })
    setRentCheck({ checkedItems: new Map() })
    setHousingCheck({ checkedItems: new Map() })
    setPropertyCheck({ checkedItems: new Map() })
    setBedCheck({ checkedItems: new Map() })
    await call()

    //  if(cityName !=="FR-paris"){dispatch(toggleCityName("FR-paris"))}
    //  if(tutionFeeRange){dispatch(toggleTutionFeeRange(""))}

    //  if(maxPrice){dispatch(toggleMaxPrice(""))}
    //  if(minPrice){dispatch(toggleMinPrice(""))}
    //  if(moveInDate){dispatch(toggleMoveInDate(""))}
    //  if(moveOutDate){dispatch(toggleMoveOutDate(""))}
    // //  dispatch(toggleLocale(""))
    //  if(propertyFeatures){dispatch(toggleReplacePropertyFeature([]))}
    //  if(bedType){dispatch(toggleReplaceBedType([]))}
    // if(housingType){dispatch(toggleReplaceHousingType([]))}
    //  if(rentType){dispatch(toggleReplaceRentType([]))}
    //  if(pageNumber!==1){dispatch(togglePageNumber("1"))}
    //  if(detail ===true){dispatch(toggleDetailPage(false))}

    //  setToggle({
    //   rent: true,
    //   location: true,
    //   locale: true,
    //   duration: true,
    //   bed: true,
    //   rentType: true,
    //   house: true,
    //   property: true,
    // })
    //  setLoading(false)
  }
  // function call(){
  //   AxiosResponseData1();
  // }
  console.log(bedCheck, "beds")
  const HandleHousingDetailPage = async (e, id) => {
    e.preventDefault()
    dispatch(toggleDetailPage(true))
    navigate("/house/?id=" + id)
  }

  const { allStrapiHousingListingSeo, allStrapiHomePage } = housing_content.data

  const seoTitle =
    allStrapiHousingListingSeo !== null
      ? allStrapiHousingListingSeo.edges[0].node.title
      : ""

  const seoMetaDescription =
    allStrapiHousingListingSeo !== null
      ? allStrapiHousingListingSeo.edges[0].node.metaDescription
      : ""

  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""

  

  const handleChange = (e) => {
    setMonths(e.target.value)
    var d = startDate ? new Date(startDate) : new Date()
    setEndDate(d)
    d.setMonth(d.getMonth() + e.target.value)
  };


  const setSliderValue = (val) => {
    dispatch(togglePageNumber(1))
    dispatch(toggleMinPrice(val[0] * 100))
    dispatch(toggleMaxPrice(val[1] * 100))
    dispatch(toggleTutionFeeRange(val[0]))
    console.log(val)

  }
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle={"Accommodation"} tag="housing">
        <div className="wrapper homepage">
          <main className="user">
            <div className="main-content housing-list-main-content">
              <div className="container">
                <div className="existing-user Filter-parent">
                  <div className="user-content Filter">
                    <div className="filter-content">
                      <div className="filters">
                        {/* <div className="housing-location-map">
                          <div className="image">
                            <img
                              src="./assets/images/wishlist/scholor.png"
                              alt="View Map"
                            />
                          </div>
                          <button className="btn-view-map" type="button">
                            View map
                          </button>
                        </div>*/}
                        {/*filters heading */}
                        <div className="filters-heading">
                          <button className="filter-title">Filters</button>
                          <button
                            className="btn btn-reset-all filter-reset"
                            onClick={e => HandleResetAll(e)}
                          >
                            <i className="fas fa-redo-alt"></i> reset all
                          </button>
                        </div>
                        {/*filters starts here */}
                        <ul className="list list-unstyled filters-menu">
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  rent: true,
                                }))
                              }
                            >
                              Location{" "}
                              {/*<i className="fas fa-sort-down"></i>*/}
                            </a>

                            {loading1 && toggle.rent && (
                              <div
                                disabled={true}
                                style={{
                                  opacity: "0.5",
                                  pointer: "none",
                                  pointerEvents: "none",
                                }}
                              >
                                <BasicAutoSuggest city={cityList} />
                              </div>
                            )}
                            {!loading1 && toggle.rent && cityList.length !== 0 && (
                              <div style={{ opacity: "1" }}>
                                <BasicAutoSuggest city={cityList} />
                              </div>
                            )}
                            {/* <div className="form-groups">
                              {locationData &&
                                locationData.map((location, index) => (
                                  <div key={index} className="form-group">
                                    <input
                                      type="checkbox"
                                      id={location.id}
                                      className="input-radio"
                                    />
                                    <label for={location.id}>
                                      {location.name}
                                    </label>
                                    <p className="items-count">{location.count}</p>
                                  </div>
                                ))}
                                </div>*/}
                          </li>
                          <li className="filter rent_price">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  location: true,
                                }))
                              }
                            >
                              Rent Price{" "}
                              {/*<i className="fas fa-sort-down"></i>*/}
                            </a>

                            <div className="form-groups">

                              {/* <Slider
                                
                                value={rvalue}
                                onChange={handleChanges}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={0}
                                step={1}
                                max={2400}
                                valueLabelFormat={valuetext}
                              /> */}

                              <RangeScrolling setprice={setSliderValue} />


                              {/* <Slider value={rvalue}

                              onChange={(e)=>{setrValue(e.target.value)}}

                                valueLabelDisplay="auto"

                              /> */}


                            </div>


                            {/* {toggle.location && (
                              <div className="form-groups">
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="0-500"
                                    className="input-radio"
                                    checked={
                                      fee.checkedItems.get("0-500") ===
                                        undefined
                                        ? false
                                        : fee.checkedItems.get("0-500")
                                    }
                                    onClick={e => HandleTutionFee(e)}
                                  />
                                  <label for="0-500">0-500 {currencySymbol}/Month</label>
                                  {<p className="items-count">12</p>}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="500-800"
                                    className="input-radio"
                                    checked={
                                      fee.checkedItems.get("500-800") ===
                                        undefined
                                        ? false
                                        : fee.checkedItems.get("500-800")
                                    }
                                    onClick={e => HandleTutionFee(e)}
                                  />
                                  <label for="500-800">500-800 {currencySymbol}/Month</label>
                                  {<p className="items-count">12</p>}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="800-1200"
                                    className="input-radio"
                                    checked={
                                      fee.checkedItems.get("800-1200") ===
                                        undefined
                                        ? false
                                        : fee.checkedItems.get("800-1200")
                                    }
                                    onClick={e => HandleTutionFee(e)}
                                  />
                                  <label for="800-1200">800-1200 {currencySymbol}/Month</label>
                                  {<p className="items-count">12</p>}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="1200-1800"
                                    className="input-radio"
                                    checked={
                                      fee.checkedItems.get("1200-1800") ===
                                        undefined
                                        ? false
                                        : fee.checkedItems.get("1200-1800")
                                    }
                                    onClick={e => HandleTutionFee(e)}
                                  />
                                  <label for="1200-1800">
                                    1200-1800 {currencySymbol}/Month
                                  </label>
                                  {<p className="items-count">12</p>}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="1800-2400"
                                    className="input-radio"
                                    checked={
                                      fee.checkedItems.get("1800-2400") ===
                                        undefined
                                        ? false
                                        : fee.checkedItems.get("1800-2400")
                                    }
                                    onClick={e => HandleTutionFee(e)}
                                  />
                                  <label for="1800-2400">
                                    1800-2400 {currencySymbol}/Month
                                  </label>
                                  {<p className="items-count">12</p>}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="radio"
                                    id="2400"
                                    className="input-radio"
                                    checked={
                                      fee.checkedItems.get("2400") === undefined
                                        ? false
                                        : fee.checkedItems.get("2400")
                                    }
                                    onClick={e => HandleTutionFee(e)}
                                  />
                                  <label for="2400" className="lowercasition">2400 and above</label>
                                  {<p className="items-count">12</p>}
                                </div>
                              </div>
                            )} */}
                          </li>

                          {/*<li className="filter">
                            <a href="#" className="list-title" onClick={()=>setToggle(prevState => ({
                              ...prevState,
                              locale: !(toggle.locale)
                          }))}>
                              locale <i className="fas fa-sort-down"></i>
                            </a>
                          {
                           toggle.locale&& <div className="form-groups">
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="en_GB"
                                  className="input-radio"
                                  checked={localeCheck.checkedItems.get("en_GB") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("en_GB")}
                                  onClick={e => HandleLocale(e)}
                                />
                                <label for="en_GB">en_GB</label>
                             
                              </div>
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="pt_PT"
                                  className="input-radio"
                                  checked={localeCheck.checkedItems.get("pt_PT") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("pt_PT")}
                                  onClick={e => HandleLocale(e)}
                                />
                                <label for="pt_PT">pt_PT</label>
                             
                              </div>
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="es_ES"
                                  checked={localeCheck.checkedItems.get("es_ES") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("es_ES")}
                                  onClick={e => HandleLocale(e)}
                                  className="input-radio"
                                />
                                <label for="es_ES">es_ES</label>
                       
                              </div>
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="fr_FR"
                                  checked={localeCheck.checkedItems.get("fr_FR") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("fr_FR")}
                                  onClick={e => HandleLocale(e)}
                                  className="input-radio"
                                />
                                <label for="fr_FR">fr_FR</label>
                             
                                <input
                                  type="checkbox"
                                  id="pl_PL"
                                  checked={localeCheck.checkedItems.get("pl_PL") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("pl_PL")}
                                  onClick={e => HandleLocale(e)}
                                  className="input-radio"
                                />
                  
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="nl_NL"
                                  checked={localeCheck.checkedItems.get("nl_NL") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("nl_NL")}
                                  onClick={e => HandleLocale(e)}
                                  className="input-radio"
                                />
                                <label for="nl_NL">nl_NL</label>
                     
                              </div>
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="zh_CN"
                                  checked={localeCheck.checkedItems.get("zh_CN") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("zh_CN")}
                                  onClick={e => HandleLocale(e)}
                                  className="input-radio"
                                />
                 
                              </div>
                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  id="de_DE"
                                  checked={localeCheck.checkedItems.get("de_DE") === undefined
                                  ? false
                                  : localeCheck.checkedItems.get("de_DE")}
                                  onClick={e => HandleLocale(e)}
                                  className="input-radio"
                                />
                                <label for="de_DE">de_DE</label>
              
                              </div>
                            </div>
                          }
                        </li>*/}
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  duration: true,
                                }))
                              }
                            >
                              Duration
                            </a>
                            {/*<i className="fas fa-sort-down"></i>*/}
                            {toggle.duration && (
                              <div className="form-groups">
                                <div>
                                  <DatePicker
                                    selected={startDate}
                                    filterDate={d => {
                                      return new Date() <= d
                                    }}
                                    placeholderText="Move in Date"
                                    dateFormat="MMMM d, yyyy"
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={date => setStartDate(date)}
                                  />


                                  <DatePicker
                                    selected={endDate}
                                    filterDate={d => {
                                      return new Date() <= d
                                    }}
                                    placeholderText="Move out Date"
                                    dateFormat="MMMM d, yyyy"
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    onChange={date => {setEndDate(date);setMonths('null')}}
                                  />

                                  <div className="form-group">
                                    <Radio.Group onChange={handleChange} value={months}>
                                      <Space direction="vertical">
                                        <Radio value={3}>3 Months</Radio>
                                        <Radio value={6}>6 Months</Radio>
                                        <Radio value={12}>12 Months</Radio>
                                      </Space>
                                    </Radio.Group>



                                  </div>

                                  {/* { <div className="form-groups">
                                    <Select placeholder={"Move out Date"} onChange={handleChange} styles={{ border: '1px dotted pink' }} options={options} />
                                  </div> } */}
                                </div>
                              </div>
                            )}
                          </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  bed: true,
                                }))
                              }
                            >
                              Bedroom Features
                              {/*<i className="fas fa-sort-down"></i>*/}
                            </a>
                            {toggle.bed && (
                              <div className="form-groups">
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="single-bed"
                                    className="input-radio"
                                    checked={
                                      bedCheck.checkedItems.get(
                                        "single-bed"
                                      ) === undefined
                                        ? false
                                        : bedCheck.checkedItems.get(
                                          "single-bed"
                                        )
                                    }
                                    onClick={e =>
                                      HandleBedType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="single-bed">Single bed</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="double-bed"
                                    className="input-radio"
                                    checked={
                                      bedCheck.checkedItems.get(
                                        "double-bed"
                                      ) === undefined
                                        ? false
                                        : bedCheck.checkedItems.get(
                                          "double-bed"
                                        )
                                    }
                                    onClick={e =>
                                      HandleBedType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="double-bed">Double beds</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="twin-bed"
                                    className="input-radio"
                                    checked={
                                      bedCheck.checkedItems.get("twin-bed") ===
                                        undefined
                                        ? false
                                        : bedCheck.checkedItems.get("twin-bed")
                                    }
                                    onClick={e =>
                                      HandleBedType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="twin-bed">Twin beds</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="multiple-bed"
                                    checked={
                                      bedCheck.checkedItems.get(
                                        "multiple-bed"
                                      ) === undefined
                                        ? false
                                        : bedCheck.checkedItems.get(
                                          "multiple-bed"
                                        )
                                    }
                                    className="input-radio"
                                    onClick={e =>
                                      HandleBedType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="multiple-bed">3+ beds</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                              </div>
                            )}
                          </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  rentType: true,
                                }))
                              }
                            >
                              Type of Places
                              {/*<i className="fas fa-sort-down"></i>*/}
                            </a>
                            {toggle.rentType && (
                              <div className="form-groups">
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="entire-place"
                                    className="input-radio"
                                    checked={
                                      rentCheck.checkedItems.get(
                                        "entire-place"
                                      ) === undefined
                                        ? false
                                        : rentCheck.checkedItems.get(
                                          "entire-place"
                                        )
                                    }
                                    onClick={e =>
                                      HandleRentType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="entire-place">Entire place</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="private-bedroom"
                                    className="input-radio"
                                    checked={
                                      rentCheck.checkedItems.get(
                                        "private-bedroom"
                                      ) === undefined
                                        ? false
                                        : rentCheck.checkedItems.get(
                                          "private-bedroom"
                                        )
                                    }
                                    onClick={e =>
                                      HandleRentType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="private-bedroom">
                                    Private bedroom
                                  </label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="shared-bedroom"
                                    className="input-radio"
                                    checked={
                                      rentCheck.checkedItems.get(
                                        "shared-bedroom"
                                      ) === undefined
                                        ? false
                                        : rentCheck.checkedItems.get(
                                          "shared-bedroom"
                                        )
                                    }
                                    onClick={e =>
                                      HandleRentType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="shared-bedroom">
                                    Shared bedroom
                                  </label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                              </div>
                            )}
                          </li>
                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  house: true,
                                }))
                              }
                            >
                              Housing Type
                              {/*<i className="fas fa-sort-down"></i>*/}
                            </a>
                            {toggle.house && (
                              <div className="form-groups">
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="private"
                                    className="input-radio"
                                    checked={
                                      housingCheck.checkedItems.get(
                                        "private"
                                      ) === undefined
                                        ? false
                                        : rentCheck.checkedItems.get("private")
                                    }
                                    onClick={e =>
                                      HandleHousingType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="private">private</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="hostel"
                                    className="input-radio"
                                    checked={
                                      housingCheck.checkedItems.get(
                                        "hostel"
                                      ) === undefined
                                        ? false
                                        : rentCheck.checkedItems.get("hostel")
                                    }
                                    onClick={e =>
                                      HandleHousingType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="hostel">hostel</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="hotel"
                                    className="input-radio"
                                    checked={
                                      housingCheck.checkedItems.get("hotel") ===
                                        undefined
                                        ? false
                                        : rentCheck.checkedItems.get("hotel")
                                    }
                                    onClick={e =>
                                      HandleHousingType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="hotel">hotel</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="residence"
                                    className="input-radio"
                                    checked={
                                      housingCheck.checkedItems.get(
                                        "residence"
                                      ) === undefined
                                        ? false
                                        : rentCheck.checkedItems.get(
                                          "residence"
                                        )
                                    }
                                    onClick={e =>
                                      HandleHousingType(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="residence">residence</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                              </div>
                            )}
                          </li>

                          <li className="filter">
                            <a
                              href="#"
                              className="list-title"
                              onClick={() =>
                                setToggle(prevState => ({
                                  ...prevState,
                                  property: true,
                                }))
                              }
                            >
                              House Features{" "}
                              {/*<i className="fas fa-sort-down"></i>*/}
                            </a>
                            {toggle.property && (
                              <div className="form-groups">
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="wi-fi"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "wi-fi"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "wi-fi"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="wi-fi">Wi-fi</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="central-heating"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "central-heating"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "central-heating"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="central-heating">
                                    Central heating
                                  </label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="cable-tv"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "cable-tv"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "cable-tv"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="cable-tv">TV</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="accessibility"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "accessibility"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "accessibility"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="accessibility">
                                    Accessibility needs
                                  </label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>

                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="dishwasher"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "dishwasher"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "dishwasher"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="dishwasher">Dishwasher</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="unfurnished"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "unfurnished"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "unfurnished"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="unfurnished">Unfurnished</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="elevator"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "elevator"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "elevator"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="elevator">Elevator</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="outdoor-area"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "outdoor-area"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "outdoor-area"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="outdoor-area">Outdoor Area</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="air-conditioning"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "air-conditioning"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "air-conditioning"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="air-conditioning">
                                    Air Conditioning
                                  </label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>

                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="microwave"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "microwave"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "microwave"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="microwave">Microwave</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="washing-machine"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get(
                                        "washing-machine"
                                      ) === undefined
                                        ? false
                                        : propertyCheck.checkedItems.get(
                                          "washing-machine"
                                        )
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="washing-machine">
                                    Washing Machine
                                  </label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id="oven"
                                    className="input-radio"
                                    checked={
                                      propertyCheck.checkedItems.get("oven") ===
                                        undefined
                                        ? false
                                        : propertyCheck.checkedItems.get("oven")
                                    }
                                    onClick={e =>
                                      HandlePropertyFeatures(
                                        e.target.id,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <label for="oven">Oven</label>
                                  {/*<p className="items-count">12</p>*/}
                                </div>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>

                      <div className="filters-results">
                        <div className="filter-course-result">
                          <h1 className="course-counts">
                            {HandleTotal_Found()}
                          </h1>
                          {/* <div className="course-sort">
                            <h5 className="sort-by">
                              sort by :
                              
                              <select>
                                <option className="show-drop-down" value="">
                                  Recommended
                                </option>
                                <option className="show-drop-down" value="">
                                  Recommended2
                                </option>
                                <option className="show-drop-down" value="">
                                  Recommended3
                                </option>
                                <option className="show-drop-down" value="">
                                  Recommended4
                                </option>
                              </select>
                            </h5>
                        </div>*/}
                        </div>
                        {loading && (
                          <div className={"centered"}>
                            <BounceIndicator size={25} color={"#0055a4"} />
                          </div>
                        )}
                        {!loading && renderData && renderData.length === 0 && (
                          <div>
                            <p>No data available</p>
                          </div>
                        )}



                        {!loading && renderData
                          ? renderData.map((e, index) => (
                            <div key={index}>
                              <div className="courses-list housing-list">
                                <div className="course-details">
                                  <div className="course-header">
                                    <div className="course-univ-fig">
                                      {/* <Slider {...settings}  className="housing-list-pictures">
                                 
                                   {e.attributes.photos.map((image, index) => 
                                        <div className="item" key={index}>
                                          <img
                                            src={`${process.env.HOUSING_LIST_IMAGE_URL}/${image.hash}/small.jpg`}
                                            alt="House Picture"
                                          />
                                        </div>
                                   )}
                                  <div>
                                   <h3>1</h3>
                                 </div>
                                 <div>
                                   <h3>2</h3>
                                 </div>
                                 <div>
                                   <h3>3</h3>
                                 </div>
                                 <div>
                                   <h3>4</h3>
                                 </div>
                                 <div>
                                   <h3>5</h3>
                                 </div>
                                 <div>
                                   <h3>6</h3>
                               </div>
                                </Slider>*/}
                                      {e.attributes.photos.length !== 0 && (
                                        <OwlCarousel
                                          className="owl-carousel owl-theme housing-list-pictures"
                                          autoplay={1}
                                          loop
                                          margin={0}
                                          nav
                                          items={1}
                                          dots={true}
                                        >
                                          {e.attributes.photos.map(
                                            (image, index) =>
                                              index < 5 ? (
                                                <div
                                                  className="item"
                                                  key={index}
                                                >
                                                  <img
                                                    src={`${process.env.HOUSING_LIST_IMAGE_URL}/${image.hash}/small.jpg`}
                                                    alt="House Picture"
                                                  />
                                                </div>
                                              ) : null
                                          )}
                                        </OwlCarousel>
                                      )}
                                    </div>
                                    <div
                                      className="course-univ-details"
                                      onClick={p =>
                                        HandleHousingDetailPage(p, e.id)
                                      }
                                    >
                                      <div className="univ-details">
                                        <h3 className="univ-name">
                                          {e.attributes.accommodation_offer
                                            ?.title
                                            ? e.attributes.accommodation_offer
                                              .title
                                            : ""}
                                        </h3>
                                        {/* <h5 className="univ-rating">
                                        {
                                          e.attributes.accommodation_provider
                                            .stats.response_rate_percentage?(((e.attributes.accommodation_provider
                                            .stats.response_rate_percentage)*5)/100):0
                                        }
                                      </h5>*/}
                                      </div>
                                      <div className="housing-rating">
                                        <p className="univ-location">
                                          {e.attributes.property.neighbourhood
                                            ?.name
                                            ? e.attributes.property
                                              .neighbourhood?.name
                                            : "Not available"}
                                        </p>
                                        {/*       <p className="ratings">
                                        (
                                        <span>
                                          {
                                            e.attributes.accommodation_offer
                                              .reviews_count ?e.attributes.accommodation_offer
                                              .reviews_count:0
                                          }
                                        </span>{" "}
                                        Reviews)
                                        </p>*/}
                                      </div>

                                      <div className="housing-spec">
                                        <ul>
                                          <li>
                                            Up To{" "}
                                            <span>
                                              {e.attributes
                                                .accommodation_offer
                                                .max_guests
                                                ? e.attributes
                                                  .accommodation_offer
                                                  .max_guests
                                                : 0}
                                            </span>{" "}
                                            people
                                          </li>
                                          <li>
                                            <span>
                                              {e.attributes.property
                                                .number_of_rooms
                                                ? e.attributes.property
                                                  .number_of_rooms
                                                : "Studio"}
                                            </span>
                                            {e.attributes.property
                                              .number_of_rooms
                                              ? " Bedrooms"
                                              : ""}
                                          </li>
                                          <li>
                                            <span>
                                              {e.attributes.property
                                                .number_of_bathrooms
                                                ? e.attributes.property
                                                  .number_of_bathrooms
                                                : 0}
                                            </span>{" "}
                                            Bathroom
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="housing-rent">
                                        <p className="month-rent">
                                          <span>
                                            {currencySymbol}{e.attributes.accommodation_offer
                                              .price.amount
                                              ? e.attributes
                                                .accommodation_offer.price
                                                .amount / 100
                                              : 0}
                                            /month
                                          </span>{" "}
                                          Bills Included
                                        </p>
                                        <div className="available">
                                          <p className="date">
                                            Available from{" "}
                                            {index === 0
                                              ? console.log(
                                                e.attributes
                                                  .accommodation_offer
                                                  .available_from,
                                                "available"
                                              )
                                              : null}
                                            <span>
                                              {HandleAvailability(
                                                e.attributes
                                                  .accommodation_offer
                                                  .available_from
                                              )}
                                            </span>
                                          </p>
                                          {/*{e.attributes.accommodation_provider.verifications.trusted && (
                                          <p className="trust">Trust Landlord</p>
                                        )}*/}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/*  <div className="course-footer" onClick={p=>HandleHousingDetailPage(p,(e.id))}>*/}
                                {/*  <button type="button" className="btn btn-wishlist">
                                  Add to wishlist
                                </button>
                                <button type="button" className="btn btn-brochure">
                                  <span>Reserve Booking</span>
                                      </button>*/}
                                {/*</div>*/}
                              </div>
                            </div>
                          ))
                          : null}
                        {/*loading2 && (
                          <div>
                            <BounceIndicator size={25} color={"#0055a4"} />
                          </div>
                        )*/}
                        {pageCount && renderData ? (
                          <div
                            class={`${loading || total_found < 10
                              ? "paginate_hidden"
                              : "paginate_visible"
                              }`}
                          >
                            <ReactPaginate
                              previousClassName={`${pageNumber === 1 ? "previous-button" : ""
                                }`}
                              nextClassName={`${pageNumber === pageCount ? "next-button" : ""
                                }`}
                              previousLabel={"prev"}
                              nextLabel={"next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={pageCount}
                              marginPagesDisplayed={5}
                              pageRangeDisplayed={4}
                              onPageChange={HandlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                              initialPage={parseInt(pageNumber) - 1}
                              forcePage={parseInt(pageNumber) - 1}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </Layout>
    </>
  )
}
export default Accommodation

export const query = graphql`
  query {
    allStrapiHousingListingSeo {
      edges {
        node {
          title
          metaDescription
        }
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
