// basic.autosuggest.js
import React, { useState } from "react"
import Autosuggest from "react-autosuggest"
import { connect, useDispatch, useSelector } from "react-redux"
import { toggleCityName, togglePageNumber } from "../state/action"
import "../assets/css/autosuggest.css"

// const companies = [
//   { id: 1, name: "Company1" },
//   { id: 2, name: "Company2" },
//   { id: 3, name: "dompany3" },
//   { id: 4, name: "Company4" },
//   { id: 5, name: "eompany5" },
//   { id: 6, name: "Company6" },
//   { id: 7, name: "Company7" },
//   { id: "FR-paris", name: "paris" }
// ];



const BasicAutoSuggest = (props) => {
  var stateName=useSelector(state => state.app.cityName)
  const [value, setValue] = useState(stateName);
  const [suggestions, setSuggestions] = useState([]);
  const [companies, setcompanies] = useState(props.city)
  const dispatch= useDispatch()
  const cityName=useSelector(state => state.app.cityName)
//  console.log(value,"cityName")
  React.useEffect(()=>{
    if(stateName){
      
      stateName = /-(.+)/.exec(stateName)[1];
      stateName = stateName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
      
    });
    stateName=stateName.replaceAll("-", " ");
     

  }
  console.log(stateName,"stateName")
  // console.log(stateName,"stateName")
    setValue(stateName);
  },[stateName])
  const lowerCasedCompanies = companies.length?companies[0].map((company) => {
 
    return {
      id: company.code,//here we want to change it to code
      name: company.name_translations[0]?.text.toLowerCase(),
    };
  }):0;
  function getSuggestions(value) {
    return lowerCasedCompanies.filter((company) =>
      company.name.includes(value.trim().toLowerCase())
    );

  }
  async function shouldRenderSuggestions(value, reason){
    // console.log(reason,value,"reason and value")
    if(reason==="input-blurred" && value===""){
      // console.log("input-blurred")
      // await dispatch(toggleCityName(cityName))
      if(stateName){
      
        stateName = /-(.+)/.exec(stateName)[1];
        stateName = stateName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
        
      });
      stateName=stateName.replaceAll("-", " ");
    }
      await setValue(stateName)

    }
  }
  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() =>{
  
           setSuggestions([])}}
        onSuggestionsFetchRequested={({ value }) => {
          
          setValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestionValue }) =>{
        
          dispatch(togglePageNumber(1))
          dispatch(toggleCityName(suggestionValue))
        }
        }
        getSuggestionValue={(suggestion) => suggestion.id}
        renderSuggestion={(suggestion) => <span>{suggestion.name}</span>}
        inputProps={{
          placeholder: "City name",
          value: value,
          onChange: (_, { newValue, method }) => {
            console.log(newValue,"method")
          //   if(newValue!==null){
      
          //     var Value = /-(.+)/.exec(newValue)[1];
          //     stateName = Value.toLowerCase().replace(/\b[a-z]/g, function(letter) {
          //       return letter.toUpperCase();
              
          //   });
          //   stateName=stateName.replaceAll("-", " ");
          // }
          if(newValue.split("-").length >1){
            stateName = /-(.+)/.exec(stateName)[1];
        stateName = stateName.toLowerCase().replace(/\b[a-z]/g, function(letter) {
          return letter.toUpperCase();
        
      });
      stateName=stateName.replaceAll("-", " ");


            setValue(stateName);

          }else{
            setValue(newValue);
          }
            
          }
        }}
        highlightFirstSuggestion={true}
        shouldRenderSuggestions={shouldRenderSuggestions}
 
      />
    </div>
  );
};

export default connect(null, { toggleCityName })(BasicAutoSuggest)
// class BasicAutoSuggest extends React.Component {
  //   constructor(props) {
  //     super(props)
  
  //     //Define state for value and suggestion collection
  //     this.state = {
  //       value: "FR-paris",
  //       suggestions: [],
  //       city: props.city || [],
  //       opacity: props.opacity,
  //     }
  //   }
  //   // componentDidMount(){
  //   //     mapStateToProps();
  //   // }
  
  //   // Collection of data
    
  //   // Filter logic
  //   getSuggestions = value => {
  //     console.log(value,"value")
  //     const inputValue = value.trim().toLowerCase()
  //     const inputLength = inputValue.length
  
  //     return inputLength === 0
  //     ? []
  //     : this.state.city[0].filter(
  //         lang =>
  //           lang.label
  //             .toLowerCase()
  //             .slice(0, inputLength) === inputValue
  //       )
  
  //     // return inputLength === 0
  //     //   ? []
  //     //   : this.state.city[0].filter(
  //     //       lang =>
  //     //         lang.name_translations[0].text
  //     //           .toLowerCase()
  //     //           .slice(0, inputLength) === inputValue
  //     //     )
  
  //     // return inputLength === 0 ? [] : this.cars.filter(lang =>
  //     //     lang.name.toLowerCase().slice(0, inputLength) === inputValue
  //     // );
  //   }
  
  //   // Trigger suggestions
  //   getSuggestionValue = suggestion => suggestion.code
  
  //   // Render Each Option
  //   renderSuggestion = suggestion => (
  //     <div>
  //       {/*suggestion.name_translations[0].text*/}
  //     {suggestion.label}
  //     </div>
  //   )
  
  //   // OnChange event handler
  //   onChange = (event, { newValue }) => {
  //     console.log(newValue,"//////////",event)
  //     this.setState({
  //       value: newValue,
  //     })
  //   }
  //   onClick=(event)=>{
  //     console.log(event,"onClickevent")
  //   }
  
  //   // Suggestion rerender when user types
  //   onSuggestionsFetchRequested = ({ value }) => {
  //     console.log(value,"this is value eventtttttttt")
  //     this.setState({
  //       suggestions: this.getSuggestions(value),
  //     })
  //     // console.log(value,"event value")
  //   }
  
  //   // Triggered on clear
  //   onSuggestionsClearRequested = () => {
  //     this.setState({
  //       suggestions: [],
  //     })
  //   }
  //   //     mapStateToProps = (state) => ({
  //   //         cityList: state.app.cityList,
  //   //       });
  
  //   //    mapDispatchToProps = (dispatch) => ({
  //   //         initiateEvents: () => dispatch(toggleCityName),
  //   //       });\
  //   // handleSubmit  = (e)  => {
  //   //     const { toggleCityName } = this.props;
  //   //     toggleCityName(this.state.value);
  //   // }
  //   // {this.state.suggestions.length ===1? this.props.toggleCityName(suggestions.map(e=>e.name_translations[0].text)):null
  //   // }
  //   render() {
  //     const { value, suggestions,opacity } = this.state
  //     const { toggleCityName } = this.props
  //     console.log(value,"values")
  
  //     // toggleCityName(value)
  
  //     // console.log(suggestions.map(e=>e.name_translations[0].text))
  //     // console.log(suggestions,"suggestions")
  
  //     //redux setups
  
  //     // Option props
  //     const inputProps = {
  //       placeholder: "Type city name",
  //       value,
  //       onChange: this.onChange,
   
        
  //     }
  
  //     // Adding AutoSuggest component
  //     return (
  //       <div className="container" style={{"--opacity-value":this.state.opacity}}>
  //      <Autosuggest
  //         suggestions={suggestions}
  //         onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
  //         onSuggestionsClearRequested={this.onSuggestionsClearRequested}
  //         getSuggestionValue={this.getSuggestionValue}
  //         renderSuggestion={this.renderSuggestion}
  //         inputProps={inputProps}
       
  //       /></div>
  //     )
  //   }
  //   // connect(this.mapStateToProps , this.mapDispatchToProps)
  // }