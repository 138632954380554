import React from 'react'

import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


function RangeScrolling(props) {


    const [rvalue, setrValue] = React.useState([0, 2400]);

    const handleChanges = (event, newValue) => {
        setrValue(newValue);
    };

    function valuetext(value) {
        return `${value} €/Month`;
    }

    return (
        <>
            <Slider
                value={rvalue}
                onChange={handleChanges}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={0}
                step={1}
                max={2400}
                disableSwap
                valueLabelFormat={valuetext}
                onChangeCommitted={()=>props.setprice(rvalue)}
            />
            <div className='row'
            >
                
                <div className='col-md'>
                    <TextField id="outlined-basic" label="min price" variant="outlined" value={`€ ${rvalue[0]}`} />
                </div>
                <div className='col-md'>
                    <TextField id="outlined-basic" label="max price" variant="outlined" value={`€ ${rvalue[1]}`} />
                </div>
            </div>

        </>
    )
}

export default RangeScrolling
